import '../styles/blog.css';
import MDEditor from '@uiw/react-md-editor';
import { useEffect, useState, useRef } from 'react';
import Markdown from 'react-markdown';
import { CreateBlogPost, GetBlogPosts, GetBlogPost, GetBlogPostComments, CreateBlogPostComment } from '../utils/blogUtils';
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize'; 
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import {signInWithGoogle} from '../utils/signInWithGoogle'
import Comment from '../components/comment'

export function MakeBlogPost(){
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [isLoading, setIsLoading] = useState(true);//eslint-disable-next-line
    const [user, setUser] = useState(null);
    const [author] = useState("Jordan Bareiss");
    const [email] = useState("jordan.bareiss1@gmail.com");
    const navigate = useNavigate();

    useEffect(() => {
        // Listen for a change in the auth state
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
          // If user is authenticated
          if (firebaseUser) {
            if(firebaseUser.email !== email){
                navigate("/home");
            }
            setUser(firebaseUser); //Set current user
          } else {
            signInWithGoogle();
          }
          setIsLoading(false); //Declare firebase as no longer loading
        });
        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        }; //Return the listener
        // eslint-disable-next-line
      }, [auth, navigate]);

    async function handleSubmit(e){
        e.preventDefault(); // Prevent page reload
        try {
            await CreateBlogPost(title, body, author, email); // Await the post creation
            alert("Success!");
            setTitle(""); // Clear the form after submission
            setBody("");
        } catch (error) {
            console.error("Error creating blog post:", error);
            alert("Failed to create post");
        }
    }

    if(isLoading){
        return(
            <p>Loading...</p>
        )
    }

    return(
        <div className='blog__page'>
            <div className="blogpost__container">
                <form onSubmit={handleSubmit}>
                    <input placeholder='title' value={title} onChange={(e)=> setTitle(e.target.value)} required={true}/>
                    <MDEditor
                        value={body}
                        autoFocus={false}
                        onChange={setBody}
                        previewOptions={{ skipHtml: true}}
                        required={true}
                        // style={{color:'white'}}
                    />
                    <button type='submit'>Post</button>
                </form>
            </div>
        </div>
    )
}

export function BlogPost() {
    const [user, setUser] = useState(null);
    const [post, setPost] = useState(null);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [commenting, setCommenting] = useState(false);
    const [commented, setCommented] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    
    const textareaRef = useRef(null); // Create a ref for the textarea

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
            if (firebaseUser) {
                setUser(firebaseUser);
            }
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [navigate]);

    useEffect(() => {
        let isMounted = true; 
        const getPost = async () => {
            const postData = await GetBlogPost(id);
            setPost(postData);
            const fetchedComments = await GetBlogPostComments(id);
            if (fetchedComments) {
                const sortedComments = fetchedComments.sort((a, b) => {
                    return a.date.toDate() - b.date.toDate();
                });
                setComments(sortedComments);
            }
        }
        if (isMounted) { 
            getPost();
        }
        return () => { 
            isMounted = false; 
        };
    }, [id]);

    useEffect(() => {
        const getComments = async () => {
            const fetchedComments = await GetBlogPostComments(id);
            if (fetchedComments) {
                const sortedComments = fetchedComments.sort((a, b) => {
                    return a.date.toDate() - b.date.toDate();
                });
                setComments(sortedComments);
            }
        }
        getComments();//eslint-disable-next-line
    }, [commented]);

    const backToPosts = () => {
        navigate("/blog");
    }

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        await CreateBlogPostComment(post.id, comment, user.displayName, user.email);
        setComment("");
        setCommenting(false);
        setCommented(!commented);
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleAddCommentClick = () => {
        setCommenting(true); // Always set commenting to true when clicked
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.focus(); // Focus the textarea immediately
            }
        }, 0); // Use a timeout to ensure the textarea is in the DOM before focusing
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) { // Prevent submission if Shift is held down (for multi-line comments)
            e.preventDefault();
            handleCommentSubmit(e);
        }
    };

    if (!post) {
        return (
            <div className="mainblog_page">
                <div className="blog__newspaper">
                    <div className='paper__texture'/>
                    <div className='paper__texture2'/>
                    <div className='paper__byline'>
                        <p>Issue 1</p>
                        <p>Loading...</p>
                        <p>R0.00</p>
                    </div>
                    <div className="blog__newspaper__wrapper">
                        <div className='blogentries__container'>
                            <div className="blogpost__entry">
                                <p>Loading...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="mainblog_page">
            <div className="blog__newspaper">
                <div className='paper__texture'/>
                <div className='paper__texture2'/>
                <div className='paper__byline' onClick={backToPosts}>
                    <p>Issue 1</p>
                    <p>{post.date.toDate().toDateString()}</p>
                    <p>R0.00</p>
                </div>
                <div className="blog__newspaper__wrapper">
                    <div className='blogentries__container'>
                        <div className="blogpost__entry" key={post.id}>
                            <div className='blogpost__header'>
                                <h1 style={{ cursor: "text" }}>{post.title}</h1>
                                <div className='blogpost__subheader'>
                                    <p>{post.author}</p>
                                    <p>{post.date.toDate().toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}</p>
                                </div>
                            </div>
                            <div className='blogbody__container'>
                                <Markdown rehypePlugins={[rehypeRaw]}>{post.body}</Markdown>
                            </div>
                            <div className='blogcomments__container'>
                                <h2>Comments</h2>
                                {comments ? (
                                    comments.map((comment) => (
                                        <Comment key={comment.id} comment={comment} id={id} user={user}/>
                                    ))
                                ) : (
                                    <></>
                                )}
                                {user ? (
                                    <div>
                                        <p style={{ cursor: 'pointer', userSelect: "none", textDecoration: "underline", opacity: "0.6" }} onClick={handleAddCommentClick}>Add a comment?</p>
                                        {commenting && (
                                            <div className='addcomment__container'>
                                                <form className="comment__form" onSubmit={handleCommentSubmit}>
                                                    <textarea
                                                        ref={textareaRef}
                                                        value={comment}
                                                        onChange={handleCommentChange}
                                                        onKeyPress={handleKeyPress}
                                                        required
                                                    />
                                                    <button type='submit'>Comment!</button>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className='logincomment__container'>
                                        <p>Want to comment?</p>
                                        <p className='comment__signin' onClick={() => signInWithGoogle()}>Sign In with Google</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Blog(){
    const [posts, setPosts] = useState([]);
    const [date, setDate] = useState("");
    const navigate = useNavigate();

    let previewWords = 60;
    if(window.innerWidth <= 468){
        previewWords = 30;
    }

    useEffect(()=>{
        let isMounted = true; // Flag to ensure it's run only once
        const getPosts = async () =>{
            const unsortedPosts = await GetBlogPosts();
            const sortedPosts = unsortedPosts.sort((a, b) => {
                return  b.date.toDate() - a.date.toDate();
            });
            setPosts(sortedPosts);
        }
        if (isMounted) { 
            getPosts();
            setDate(new Date().toDateString());
        }
        return () => { 
            isMounted = false; // Clean-up function to avoid running the effect again
        };
    },[])

    const getFirstWords = (text, count) => {
        return text.split(' ').slice(0, count).join(' ') + '...'; // Adds ellipsis at the end
    };

    const handlePostClick = (id) =>{
        navigate(`/blog/${id}`)
    }

    return(
        <div className="mainblog_page">
            <div className="blog__newspaper">
                <div className='paper__texture'/>
                <div className='paper__texture2'/>
                <div className='paper__byline'>
                    <p>Issue {posts.length}</p>
                    <p>{date}</p>
                    <p>R0.00</p>
                </div>
                <div className="blog__newspaper__wrapper">
                    <div className='blogentries__container'>
                    {posts.length > 0 && posts.map((post) => (
                        <div className="blogpost__entry" key={post.id}>
                            <div className='blogpost__header'>
                                <h1 onClick={() => handlePostClick(post.id)}>{post.title}</h1>
                                <div className='blogpost__subheader'>
                                    <p>{post.date.toDate().toDateString().substring(3)}</p>
                                    <p>{post.author}</p>
                                </div>
                            </div>
                            <div className='bodysnippet__container'>
                                <Markdown rehypePlugins={[rehypeSanitize]}>{getFirstWords(post.body, previewWords)}</Markdown>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog;