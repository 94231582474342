import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { addNewUser } from "./addNewUser";

export const signInWithGoogle = () =>{
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)//;
    //getRedirectResult(auth)
      .then((result) => {
            const user = result.user;
            let email = user.email;
            let displayName = user.displayName;
            let firstName = displayName.split(" ")[0]; // Gets user's first name
            let lastName = displayName.slice(displayName.indexOf(firstName) + firstName.length + 1); // Gets user's last name

            addNewUser(firstName, lastName, email); // Calls the API to add new user to the database
          
      }).catch((error) => {
          // Handle Errors here.
          console.log(error); // REVIEW: The app running doesn't like console.error here, but the tests don't like console.log
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.customData.email;
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
      })
      
  }