import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyALsE4aON1UYZDsaZt4B0RfRG_5RlMEEAA",
  authDomain: "brdnstudio.firebaseapp.com",
  projectId: "brdnstudio",
  storageBucket: "brdnstudio.appspot.com",
  messagingSenderId: "927261934150",
  appId: "1:927261934150:web:2666f4621927493d380047",
  measurementId: "G-8SMZKL65TT"
};

export const app = initializeApp(firebaseConfig);
export const db =  getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);