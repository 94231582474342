import React, { useState, useEffect } from 'react';
import '../styles/archive.css';
import multiImg from '../assets/multipleImages.png'
import loadingGif from "../assets/loading.gif"

const PopupImage = ({ imageSrc, subfolderImages, onClose }) => {
  const [currentImage, setCurrentImage] = useState(imageSrc); // Keep track of the current full image being displayed
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomProps, setZoomProps] = useState({ zoomX: '50%', zoomY: '50%', scale: 1 });

  useEffect(() => {
    if (imageSrc) {
      document.body.style.overflow = 'hidden'; // Disable scrolling when popup is open
    } else {
      document.body.style.overflow = 'auto'; // Restore scrolling when popup is closed
    }
    setCurrentImage(imageSrc);
    // Clean up on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [imageSrc]);

  // Handle zoom-in and zoom-out functionality
  const handleZoom = (e) => {
    if (isZoomed) {
      // Reset zoom on click
      setIsZoomed(false);
      setZoomProps({ zoomX: '50%', zoomY: '50%', scale: 1 });
    } else {
      // Zoom in on click
      setIsZoomed(true);
      const x = (e.nativeEvent.offsetX / e.target.offsetWidth) * 100;
      const y = (e.nativeEvent.offsetY / e.target.offsetHeight) * 100;
      setZoomProps({ zoomX: `${x}%`, zoomY: `${y}%`, scale: 2 });
    }
  };

  // Handle mouse movement for zoom functionality
  const handleMouseMove = (e) => {
    if (isZoomed) {
      const x = (e.nativeEvent.offsetX / e.target.offsetWidth) * 100;
      const y = (e.nativeEvent.offsetY / e.target.offsetHeight) * 100;
      setZoomProps({ zoomX: `${x}%`, zoomY: `${y}%` });
    }
  };

  // Set the clicked thumbnail as the current enlarged image
  const handleThumbnailClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsZoomed(false); // Reset zoom when changing image
    setZoomProps({ zoomX: '50%', zoomY: '50%', scale: 1 });
  };

  if (!currentImage){
    return null;
  }

  return (
    <>
      <div className="popup__overlay" onClick={onClose}>
        <button onClick={onClose}>&times;</button>
      </div>
      <div className="popup__container">
        <div className="popup__image">
          <img alt="loading-gif" className="loading__gif" src={loadingGif}/>
          <p className='loading__text'>Images are uncompressed; they may take a while to load.</p>
          <img
            src={currentImage}
            alt="popup"
            style={{
              transform: `scale(${zoomProps.scale})`,
              transformOrigin: `${zoomProps.zoomX} ${zoomProps.zoomY}`,
              transition: 'transform 0.3s ease',
            }}
            className={isZoomed ? 'zoomed' : ''}
            onClick={handleZoom}
            onMouseMove={handleMouseMove}
          />
        </div>
        {subfolderImages && subfolderImages.length > 0 && (
          <div className="popup__thumbnails">
            {subfolderImages.map((thumbnailSrc, idx) => (
              <img
                key={idx}
                src={thumbnailSrc}
                alt="thumbnail"
                className="thumbnail"
                onClick={() => handleThumbnailClick(thumbnailSrc.replace("preview", "full"))} // Set the clicked image as the full-size image
                style={{
                  border: currentImage === thumbnailSrc.replace("preview", "full") ? '2px solid #4287f5' : 'none', // Highlight the active image
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const ImageGrid = ({ folderNames, containerId, colCount }) => {
  const [images, setImages] = useState([]);
  const [popupImage, setPopupImage] = useState(null); // Store full image and subfolder images

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/assets/archive/manifest.json`);
        const manifest = await response.json();
        let loadedImages = [];

        // Iterate over each folder specified in folderNames
        for (const folderName of folderNames) {
          const folderData = manifest[folderName];

          if (folderData && folderData.preview) {
            const previewFolder = folderData.preview;

            // Function to get the first image from a folder (for subfolders)
            const getFirstImage = (folder) => {
              if (folder.files && folder.files.length > 0) {
                return folder.files[0];  // Return first image if available
              }
              return null; // No images found
            };

            // Iterate through each item (file or subfolder) in the preview folder
            for (const key in previewFolder) {
              const item = previewFolder[key];

              // If the item is a subfolder, get the first image in the subfolder
              if (typeof item === 'object') {
                const firstImageInSubfolder = getFirstImage(item);
                if (firstImageInSubfolder) {
                  // Push the first image and subfolder details into loadedImages
                  loadedImages.push({
                    src: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/preview/${key}/${firstImageInSubfolder}`,
                    fullSrc: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/full/${key}/${firstImageInSubfolder}`,
                    subfolderImages: item.files.map((file) => ({
                      src: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/preview/${key}/${file}`,
                      fullSrc: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/full/${key}/${file}`,
                    })),
                  });
                }
              }

              // If the item is a 'files' array (the preview folder itself), add all images
              if (key === 'files' && Array.isArray(item)) {
                const folderImages = item.map((file) => ({
                  src: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/preview/${file}`,
                  fullSrc: `${process.env.PUBLIC_URL}/assets/archive/artwork/${folderName}/full/${file}`,
                }));
                loadedImages = [...loadedImages, ...folderImages];
              }
            }
          } else {
            console.error(`Folder "${folderName}" not found in the manifest`);
          }
        }

        setImages(loadedImages);
      } catch (error) {
        console.error('Error loading manifest:', error);
      }
    };

    loadImages();
  }, [folderNames]);

  // Create an array of columns for the grid layout
  const columns = Array.from({ length: colCount }, () => []);

  // Distribute the images across the columns
  images.forEach((img, index) => {
    columns[index % colCount].push(img);
  });

  const setHidden = () => {
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  return (
    <>
      <div className="grid__container">
        <div className="column__container">
        {columns.map((column, colIdx) => (
          <div className="img__container" key={colIdx} id={`${containerId}${colIdx}`} style={{ '--col-count': colCount }}>
            {column.map((img, imgIdx) => (
              <div key={`${colIdx}-${imgIdx}`} style={{ position: 'relative', display: 'inline-block' }}>
                <img
                  src={img.src}
                  alt="grid item"
                  loading="lazy"
                  onClick={() => {
                    if (img.subfolderImages) {
                      setPopupImage({
                        fullSrc: img.fullSrc,
                        subfolderImages: img.subfolderImages.map((subImg) => subImg.src),
                      });
                    } else {
                      setPopupImage({
                        fullSrc: img.fullSrc,
                        subfolderImages: [],
                      });
                    }
                    setHidden();
                  }}
                />
                {img.subfolderImages && img.subfolderImages.length > 0 && (
                  <img
                    src={multiImg}
                    alt="multiple images"
                    className="overlay-icon"
                    style={{width: "20px"}}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
        </div>
      </div>
      {popupImage && (
        <PopupImage
          imageSrc={popupImage.fullSrc}
          subfolderImages={popupImage.subfolderImages} 
          onClose={() => { setPopupImage(null); setHidden(); }}
        />
      )}
    </>
  );
};

const ArchiveCollection = ({year}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  }

  let columns = 4;
  if(window.innerWidth <= 468){
    columns = 3;
  }

  return (
    <div className='archivecollection__container'>
        <div className='header__container' onClick={toggleOpen}>
          <h2>{year}</h2>
          <p>{open? "hide":"show"}</p>
        </div>
        {open && (
          <ImageGrid folderNames={[year]} containerId="ArcCol" colCount={columns} />
        )}
      </div>
  );
}

const Archive = () => {
  const [popupImage, setPopupImage] = useState(null);
  // const [bird1Step, setBird1Step] = useState(0);
  // const [bird2Step, setBird2Step] = useState(2);
  // const [bird3Step, setBird3Step] = useState(4);
  // const [is1Reversing, setIs1Reversing] = useState(false);
  // const [is2Reversing, setIs2Reversing] = useState(false);
  // const [is3Reversing, setIs3Reversing] = useState(false);
  const showcaseFolders = ['Showcase'];

  let showcaseColumns = 3;
  if(window.innerWidth <= 468){
    showcaseColumns = 2;
  }

  // useEffect(() => {
  //   const birdSteps = 4; 
  //   const animationInterval = 275;
  //   let birdAnimation;

  //   birdAnimation = setInterval(() => {
  //     setBird1Step((prevStep) => {
  //       if (is1Reversing && prevStep === 0) {
  //         setIs1Reversing(false); 
  //         return prevStep + 1;  
  //       }
  //       if (!is1Reversing && prevStep === birdSteps) {
  //         setIs1Reversing(true);  
  //         return prevStep - 1;   
  //       }
  //       return is1Reversing ? prevStep - 1 : prevStep + 1;
  //     });
  //     setBird2Step((prevStep) => {
  //       if (is2Reversing && prevStep === 0) {
  //         setIs2Reversing(false); 
  //         return prevStep + 1;  
  //       }
  //       if (!is2Reversing && prevStep === birdSteps) {
  //         setIs2Reversing(true);  
  //         return prevStep - 1;   
  //       }
  //       return is2Reversing ? prevStep - 1 : prevStep + 1;
  //     });
  //     setBird3Step((prevStep) => {
  //       if (is3Reversing && prevStep === 0) {
  //         setIs3Reversing(false); 
  //         return prevStep + 1;  
  //       }
  //       if (!is3Reversing && prevStep === birdSteps) {
  //         setIs3Reversing(true);  
  //         return prevStep - 1;   
  //       }
  //       return is3Reversing ? prevStep - 1 : prevStep + 1;
  //     });
  //   }, animationInterval);

  //   return () => {
  //     clearInterval(birdAnimation);
  //   };
  // }, [is1Reversing, is2Reversing, is3Reversing]); 

  return (
    <div id="archive">
      <PopupImage imageSrc={popupImage} subfolderImages = {[]} onClose={() => setPopupImage(null)} />
      <div className="highlighted__work">
        <img
          src={`${process.env.PUBLIC_URL}/assets/archive/artwork/highlightpreview.png`}
          id="main--highlight"
          alt="highlight"
          onClick={()=>{setPopupImage(`${process.env.PUBLIC_URL}/assets/archive/artwork/highlightfull.png`);}}
        />
      </div>
      <ImageGrid folderNames={showcaseFolders} containerId="ShowCol" colCount={showcaseColumns} />
      <ArchiveCollection year = {'2024'} />
      <ArchiveCollection year = {'2023'} />
      <ArchiveCollection year = {'2022'} />
      <ArchiveCollection year = {'2021'} />
      {/* <div className="bird__background"/>
      <div className="bird__container">
        <div className={`bird__animation`} style={{backgroundPosition: `${(bird1Step / 4) * 100}% 0%`, transform: "translate(0%, -25%) scale(0.5)"}}/>
        <div className={`bird__animation`} style={{backgroundPosition: `${(bird2Step / 4) * 100}% 0%`, transform: "translate(-50%, -25%) scale(0.5)"}}/>
        <div className={`bird__animation`} style={{backgroundPosition: `${(bird3Step / 4) * 100}% 0%`, transform: "translate(-100%, -25%) scale(0.5)"}}/>
      </div> */}
    </div>
  );
};

export default Archive;
