import '../styles/blog.css';
import { CreateBlogPostReply } from '../utils/blogUtils';
import { signInWithGoogle } from '../utils/signInWithGoogle';
import { useState, useRef } from 'react';
import Reply from './reply';
import { Timestamp } from 'firebase/firestore';

export default function Comment({ comment, id, user }) {
    const [replying, setReplying] = useState(false);
    const [reply, setReply] = useState("");
    const [showReplies, setShowReplies] = useState(false);
    const [replies, setReplies] = useState(comment.replies);
    
    const textareaRef = useRef(null); // Create a ref for the textarea

    const handleCommentReplySubmit = async (e) => {
        e.preventDefault();
        await CreateBlogPostReply(id, comment.id, reply, user.displayName, user.email, comment.author);
        
        const replyInfo = {
            body: reply,
            author: user.displayName,
            email: user.email,
            date: Timestamp.fromDate(new Date()),
            replyingTo: comment.author
        };
        if(replies){
            setReplies((replies) => [...replies, replyInfo]);
        }else{
            setReplies([replyInfo]);
        }
        
        setReply("");
        setReplying(false);
    }

    const handleReplyChange = (e) => {
        setReply(e.target.value);
    };

    const handleReplyClick = () => {
        setReplying(true);
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }, 0); 
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleCommentReplySubmit(e);
        }
    };

    return (
        <div className='blogcomment__container'>
            <div className='comment__metainfo'>
                <p>{comment.author}</p>
                <p>{comment.date.toDate().toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })}</p>
            </div>
            <p>{comment.body}</p>
            <p onClick={handleReplyClick} className='comment__replyclause'>Reply?</p>
            {replying && (
                <div className='replying__container'>
                    {user ? (
                        <form className="comment__form" onSubmit={handleCommentReplySubmit}>
                            <textarea
                                ref={textareaRef} // Assign ref to the textarea
                                value={reply}
                                onChange={handleReplyChange}
                                onKeyPress={handleKeyPress}
                                required={true}
                            ></textarea>
                            <button type='submit'>Reply!</button>
                        </form>
                    ) : (
                        <div className='logincomment__container'>
                            <p>Want to reply?</p>
                            <p className='comment__signin' onClick={signInWithGoogle}>Sign In with Google</p>
                        </div>
                    )}
                </div>
            )}
            {replies && (
                <>
                    <p style={{ cursor: 'pointer', userSelect: "none", textDecoration: "underline", opacity: "0.6" }} onClick={() => setShowReplies(!showReplies)}>
                        {showReplies ? "Hide " : "Show "} replies
                    </p>
                    {showReplies && (
                        <div className='commentreplies__container'>
                            {replies
                                .sort((a, b) => a.date.toDate() - b.date.toDate()) // Sort replies by date, oldest first
                                .map((reply) => (
                                    <Reply key={reply.id} comment={comment} id={id} user={user} replyObj={reply} replies={replies} setReplies={setReplies} />
                                ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
