import { useState } from 'react';
import emailjs from '@emailjs/browser';
import "../styles/contact.css"

const ContactForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);
    const sendEmail = (e) => {
      e.persist();
      e.preventDefault();
      setIsSubmitting(true);
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          e.target,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            setStateMessage('Message sent!');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          },
          (error) => {
            setStateMessage('Something went wrong, please try again later');
            console.log(error);
            console.log(process.env.REACT_APP_PUBLIC_KEY);
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          }
        );
      
      // Clears the form after sending the email
      e.target.reset();
    };
    return (
        <main >
            <div className='contactpage__full'/>
            <div className='contactpage__container'>
                <div className='contactform__container'></div>
                <h1 className='contact__header'>Contact Me</h1>
                <form onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="user_name" required={true}/>
                    <label>Email</label>
                    <input type="email" name="user_email" required={true}/>
                    <label>Message</label>
                    <textarea name="message" required={true}/>
                    <input id="form__submit" type="submit" value="Send" disabled={isSubmitting} />
                    {stateMessage && <p>{stateMessage}</p>}
                </form>
                <div className='second__halftone' style={{top: "0", left: "0", opacity: "20%"}}/>
            </div>
        </main>
    );
  };
  export default ContactForm;