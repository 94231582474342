import { Link, Outlet } from "react-router-dom";
import "../styles/navMenu.css"
import { useState } from "react";

function NavMenu() {
  const [showing, setShowing] = useState(true);
  return (
    <main>
      <nav className="navigation-container">
        {!showing && (
           <p className="show__hide" onClick={() =>setShowing(!showing)}>show</p>
        )}
        <div className={`inner-navigation-container ${showing ? "expanded" : "collapsed"}`}>
          <p>Navigation</p>
          <div className="link-container">
            <Link to="/home" className="nav-link">home</Link>
            <Link to="/archive" className="nav-link">archive</Link>
            <Link to="/contact" className="nav-link">contact</Link>
            <Link to="/blog" className="nav-link">blog</Link>
            {showing && (
            <p className="show__hide" onClick={() =>setShowing(!showing)}>hide</p>
            )}
          </div>
        </div>
      </nav>
      <Outlet/>
    </main>
  );
}
  
  export default NavMenu;