import { useEffect, useState, useRef, useCallback } from "react";
import "../styles/home.css";
import insta from "../assets/Instagram_icon.png.webp"
import folder from "../assets/folder.png"
import notes from "../assets/notesIcon.png"
import messages from "../assets/messages.png"
import { useNavigate } from "react-router-dom";

export const throttle = (f) => {
  let token = null,
    lastArgs = null;
  const invoke = () => {
    f(...lastArgs);
    token = null;
  };
  const result = (...args) => {
    lastArgs = args;
    if (!token) {
      token = requestAnimationFrame(invoke);
    }
  };
  result.cancel = () => token && cancelAnimationFrame(token);
  return result;
};

const id = (x) => x;
const useDraggable = ({ onDrag = id } = {}) => {
  const [pressed, setPressed] = useState(false);
  const position = useRef({ x: 0, y: 0 });
  const offset = useRef({ x: 0, y: 0 }); // Added to store the offset between touch point and element's position
  const ref = useRef();

  const unsubscribe = useRef();
  const legacyRef = useCallback((elem) => {
    ref.current = elem;
    if (unsubscribe.current) {
      unsubscribe.current();
    }
    if (!elem) {
      return;
    }

    // Handle mouse down and touch start with offset calculation
    const handlePressStart = (e) => {
      e.preventDefault(); // Prevent default behavior on mobile (like scrolling)
      e.target.style.userSelect = "none";
      setPressed(true);

      const rect = elem.getBoundingClientRect();

      // Calculate the offset between the touch point and the element's top-left corner
      const eventX = e.touches ? e.touches[0].clientX : e.clientX;
      const eventY = e.touches ? e.touches[0].clientY : e.clientY;

      offset.current = {
        x: eventX - rect.left,
        y: eventY - rect.top,
      };

      position.current = {
        x: rect.left,
        y: rect.top,
      };
    };

    elem.addEventListener("mousedown", handlePressStart);
    elem.addEventListener("touchstart", handlePressStart);

    unsubscribe.current = () => {
      elem.removeEventListener("mousedown", handlePressStart);
      elem.removeEventListener("touchstart", handlePressStart);
    };
  }, []);

  useEffect(() => {
    if (!pressed) {
      return;
    }

    const handleMove = throttle((event) => {
      if (!ref.current || !position.current) {
        return;
      }

      const elem = ref.current;

      // Determine if it's a mouse or touch event
      const eventX = event.touches ? event.touches[0].clientX : event.clientX;
      const eventY = event.touches ? event.touches[0].clientY : event.clientY;

      // Adjust the position based on the offset calculated during press start
      position.current = onDrag({
        x: eventX - offset.current.x,
        y: eventY - offset.current.y,
      });

      elem.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`;
    });

    const handlePressEnd = (e) => {
      e.target.style.userSelect = "auto";
      setPressed(false);
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("mouseup", handlePressEnd);

    document.addEventListener("touchmove", handleMove);
    document.addEventListener("touchend", handlePressEnd);

    return () => {
      handleMove.cancel();
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handlePressEnd);

      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handlePressEnd);
    };
  }, [pressed, onDrag]);

  return [legacyRef, pressed];
};

const FanOutElement = ({ initialPosition, label, onClickFunction, icon, size }) => {
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const [isDragging, setIsDragging] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const startDragPos = useRef({ x: 0, y: 0 });

  const handleDrag = useCallback(
    ({ x, y }) => ({
      x: Math.max(0, x),
      y: Math.max(0, y),
    }),
    []
  );

  const [ref] = useDraggable({
    onDrag: (draggedPosition) => {
      const newPosition = handleDrag(draggedPosition);
      setPosition(newPosition);

      const distanceMoved = Math.hypot(
        draggedPosition.x - startDragPos.current.x,
        draggedPosition.y - startDragPos.current.y
      );
      if (distanceMoved > 5) {
        setIsDragging(true);
      }

      return newPosition;
    },
  });

  const handlePressStart = (e) => {
    startDragPos.current = { x: position.x, y: position.y };
    setIsDragging(false);
  };

  const handleClick = (e) => {
    if (!isDragging) {
      onClickFunction();
    }
  };

  const handleTouchEnd = (e) => {
    if (!isDragging) {
      onClickFunction();
    }
    setIsDragging(false); // Reset dragging state on touch end
  };

  useEffect(() => {
    if (initialPosition) {
      setTimeout(() => {
        setPosition(initialPosition); // Transition to the final position
        setIsVisible(true); // Make the element visible
      }, 50); // Delay to ensure smoothness (optional)
    }
  }, [initialPosition]);

  return (
    <div
      ref={ref}
      className={`fan-out-element ${isVisible ? 'visible' : ''}`}
      onMouseDown={handlePressStart}
      onTouchStart={handlePressStart}
      onClick={handleClick}
      onTouchEnd={handleTouchEnd} // Handle touch end
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        backgroundImage: `url(${icon})`,
        width: `${size}px`,
        height: `${size}px`,
        backgroundSize: `cover`,
        transition: isDragging ? 'none' : 'transform 0.4s ease-in-out, opacity 0.4s ease-out',
      }}
    >
      <p style={{ marginTop: `${size}px` }}>{label}</p>
    </div>
  );
};


function FanOut() {
  const navigate = useNavigate();
  const iconSize = 60;

  const onArchiveClick = () =>{
    navigate(`/archive`);
  }

  const onContactClick = () =>{
    navigate(`/contact`);
  }

  const onBlogClick = () =>{
    navigate(`/blog`);
  }

  const onInstagramClick = () =>{
    window.open("https://www.instagram.com/brdnstudio/", "_blank")
  }

  const generatePosition = () =>{
    const windowWidth = window.innerWidth; 
    const windowHeight = window.innerHeight;
    let xPos; 
    let yPos;
    let centerX = 350;
    let centerY = 175;
    let borderX = 100;
    let borderY = 75;

    const xUseFirstRange = Math.random() < 0.5; 
    const yUseFirstRange = Math.random() < 0.5;

    if(windowWidth <= 768){
      centerX = 0;
      centerY = 100;
      borderX = 100;
      borderY = 15;
    }

    if (xUseFirstRange) {
      xPos = Math.floor(Math.random() * (centerX - borderX) + borderX);
    } else {
      const max = windowWidth - 100;
      if (max > 2*centerX) {
        xPos = Math.floor(Math.random() * (max - 2*centerX) + 2*centerX);
      } else {
        xPos = Math.floor(Math.random() * (centerX - borderX) + borderX);
      }
    }

    if (yUseFirstRange){
      yPos = Math.floor(Math.random() * (centerY - borderY) + borderY);
    } else {
      const max = windowHeight - borderY;
      if (max > 2*centerY) {
        yPos = Math.floor(Math.random() * (max - 2*centerY) + 2*centerY);
      } else {
        yPos = Math.floor(Math.random() * (centerY - borderY) + borderY);
      }
    }
    if(windowWidth <= 768 && yPos < 100 && xPos < 50){
      xPos+=60;
    }

    if(windowWidth <= 768 && yPos > windowHeight-75){
      yPos-=50;
    }
    return { x: xPos, y: yPos };
  }
  return (
    <main className="fan__out__container">
      <FanOutElement 
        initialPosition={generatePosition}
        label = {"Instagram"}
        onClickFunction = {onInstagramClick}
        icon = {insta}
        size = {iconSize}/>
      <FanOutElement 
        initialPosition={generatePosition}
        label = {"Archive"}
        onClickFunction = {onArchiveClick}
        icon = {folder}
        size = {iconSize}/>
      <FanOutElement 
        initialPosition={generatePosition}
        label = {"Contact"}
        onClickFunction = {onContactClick}
        icon = {messages}
        size = {iconSize}/>
      <FanOutElement 
        initialPosition={generatePosition}
        label = {"Blog"}
        onClickFunction = {onBlogClick}
        icon = {notes}
        size = {iconSize}/>
    </main>
  );
}

function Home(){
    const [mainStep, setMainStep] = useState(0);
    const [spriteStep, setSpriteStep] = useState(0);
    const [arrowStep, setArrowStep] = useState(0);
    const [isArrowVisible, setIsArrowVisible] = useState(false);
    const [arrowCountdown, startArrowCountdown] = useState(true);
    const [animationDone, setAnimationDone] = useState(false);

    useEffect(() => {
        const backgroundFlower = document.querySelector('.main__container');
        let scalingFactor = 1.12;
        if((window.innerHeight / 375) * scalingFactor > 2.3){
          scalingFactor = 1.17;
        }
        // backgroundFlower.style.setProperty('--scale',  Math.min((window.innerHeight / 375) *scalingFactor, 2.3));
        backgroundFlower.style.setProperty('--scale', (window.innerHeight / 375) *scalingFactor);
      }, []);
    
    useEffect(() => {
        const handleScroll = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        const scrollFraction = (scrollTop / maxScroll)*1.1;

        const mainSteps = 29;  // Number of steps for main__container
        const spriteSteps = 14;  // Number of steps for sprite__container

        const mainCurrentStep = Math.min(Math.floor(scrollFraction * mainSteps), 29);
        const spriteCurrentStep =  Math.min(Math.floor(scrollFraction * spriteSteps), 14);

        setMainStep(mainCurrentStep);
        setSpriteStep(spriteCurrentStep);

        if(scrollFraction>0){
          startArrowCountdown(false);
          setIsArrowVisible(false);
        }else if(scrollFraction === 0){
          startArrowCountdown(true);
        }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };//eslint-disable-next-line
    }, []);

    useEffect(() => {
      const arrowSteps = 8; 
      const animationInterval = 275; 
      const delayBeforeStart = 10000; 
      let arrowAnimation; // Reference for the setInterval

      // Start the arrow animation after the delay
      if(arrowCountdown){
        const startArrowAnimation = setTimeout(() => {
          setIsArrowVisible(true);
          arrowAnimation = setInterval(() => {
              setArrowStep(prevStep => (prevStep + 1) % arrowSteps);
          }, animationInterval);
        }, delayBeforeStart);
        return () => {
          clearTimeout(startArrowAnimation);
          clearInterval(arrowAnimation);
      };
      }
      //eslint-disable-next-line
  }, [, arrowCountdown]);

  useEffect(() => {
    if((spriteStep / 14) * 100 === 100){
      setAnimationDone(true);
    }else{
      setAnimationDone(false);
    }
  }, [spriteStep])

  return (
    <main className="home__main">
      <div
        className="main__container"
        style={{
          '--scale': 1,
          backgroundPosition: `${(mainStep / 29) * 100}% 0%`,  // Adjust background position based on scroll
        }}
      />
        <div
          className="sprite__container"
          style={{
            backgroundPosition: `${(spriteStep / 14) * 100}% 0%`,  // Adjust background position based on scroll
          }}
        />
        {animationDone && (
          <FanOut/>
        )}
        <div>
          <div className={`arrow__container ${isArrowVisible? "" : "hidden"}`} id='left-arrow' style={{backgroundPosition: `${(arrowStep / 7) * 100}% 0%`, transform: "translate(-15%, -70%) scale(0.5)"}}/>
          <div className={`arrow__container ${isArrowVisible? "" : "hidden"}`} id='mid-arrow' style={{backgroundPosition: `${(arrowStep / 7) * 100}% 0%`, transform: "translate(-50%, -70%) scale(0.5)"}}/>
          <div className={`arrow__container ${isArrowVisible? "" : "hidden"}`} id='right-arrow' style={{backgroundPosition: `${(arrowStep / 7) * 100}% 0%`, transform: "translate(-85%, -70%) scale(0.5)"}}/>
          <p className={`scroll__prompt ${isArrowVisible? "" : "hidden"}`}>Scroll</p>
        </div>
      <div className="first__halftone"></div>
      <div className="second__halftone"></div>
    </main>
  );
};

export default Home;