import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

export const addNewUser = async (firstName, lastName, userEmail) =>{
    const userDocRef = doc(db, "users", userEmail);

        try {
            const userData = {
                firstName: firstName,
                lastName: lastName,
                };
            await setDoc(userDocRef, userData);
            console.log("User added successfully");
        } catch (error) {
            // Handle any internal errors
            console.error("Error adding user:", error);
        }
}