import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import NavMenu from "./components/navMenu";
import Archive from "./pages/archive";
import ContactForm from "./pages/contact";
import Blog from "./pages/blog";
import { MakeBlogPost, BlogPost } from "./pages/blog";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<NavMenu/>}>
            <Route index element={<Home/>}/>
            <Route path="/home" element={<Home />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/blog/create" element={<MakeBlogPost />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
