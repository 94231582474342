import '../styles/blog.css';
import { CreateBlogPostReply } from '../utils/blogUtils';
import { signInWithGoogle } from '../utils/signInWithGoogle';
import { Timestamp } from 'firebase/firestore';
import { useState, useRef } from 'react';

export default function Reply({ comment, id, user, replyObj, replies, setReplies }) {
    const [replying, setReplying] = useState(false);
    const [reply, setReply] = useState("");
    
    const textareaRef = useRef(null); // Create a ref for the textarea

    const handleReplySubmit = async (e) => {
        e.preventDefault();
        await CreateBlogPostReply(id, comment.id, reply, user.displayName, user.email, replyObj.author);
        
        const replyInfo = {
            body: reply,
            author: user.displayName,
            email: user.email,
            date: Timestamp.fromDate(new Date()),
            replyingTo: replyObj.author
        };
        
        setReplies((prevReplies) => {
            if (prevReplies) {
                return [...prevReplies, replyInfo];
            } else {
                return [replyInfo];
            }
        });
        
        setReply("");
        setReplying(false);
    }

    const handleReplyChange = (e) => {
        setReply(e.target.value);
    };

    const handleReplyClick = () => {
        setReplying(true); 
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }, 0); 
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleReplySubmit(e);
        }
    };

    return (
        <div className='reply__container'>
            <div className='comment__metainfo'>
                <p>{replyObj.author}</p>
                <p>{replyObj.date.toDate().toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })}</p>
            </div>
            <p><span style={{ fontWeight: "bold" }}>@{replyObj.replyingTo}:</span> {replyObj.body}</p>
            <p onClick={handleReplyClick} className='comment__replyclause'>Reply?</p>
            {replying && (
                <div className='replying__container'>
                    {user ? (
                        <form className="comment__form" onSubmit={handleReplySubmit}>
                            <textarea
                                ref={textareaRef} // Assign ref to the textarea
                                value={reply}
                                onChange={handleReplyChange}
                                onKeyPress={handleKeyPress}
                                required={true}
                            ></textarea>
                            <button type='submit'>Reply!</button>
                        </form>
                    ) : (
                        <div className='logincomment__container'>
                            <p>Want to reply?</p>
                            <p className='comment__signin' onClick={signInWithGoogle}>Sign In with Google</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
