import { db } from "../firebase";
import { addDoc, collection, getDocs, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";


export async function CreateBlogPost(title, body, author, email){
    try {
        const blogPostData = {
            title,
            body,
            author,
            email,
            date: new Date(),
        };
        const docRef = await addDoc(collection(db, 'blogposts'), blogPostData);
        console.log("Blog post created with ID: ", docRef.id);
        return docRef;
    } catch (error) {
        console.error("Error adding document: ", error);
        throw error; // Rethrow the error to handle it in the component
    }
}

export async function GetBlogPosts(){
    const blogsQuery = collection(db, "blogposts");
    const blogsSnapshot = await getDocs(blogsQuery);
    const blogPosts = [];

    blogsSnapshot.forEach((doc)=>{
        blogPosts.push({
            id: doc.id,
            ...doc.data()
        });
    })

    return blogPosts;
}

export async function GetBlogPost(id){
    const postRef = doc(db, "blogposts", id);
    const postSnapshot = await getDoc(postRef);

    if (postSnapshot.exists()) {
        return { id: postSnapshot.id, ...postSnapshot.data() };
    } else {
        throw new Error("Post not found");
    }
}

export async function GetBlogPostComments(id) {
    const commentsRef = collection(db, "blogposts", id, "comments");
    const commentSnapshot = await getDocs(commentsRef);
    
    if (!commentSnapshot.empty) { // Use .empty to check if the snapshot has any documents
        const comments = commentSnapshot.docs.map(doc => ({
            id: doc.id,  // Get the document ID
            ...doc.data() // Get the document data
        }));
        return comments;
    } else {
       return null;
    }
}

// Create a new comment for a specific blog post
export async function CreateBlogPostComment(id, body, author, email) {
    try {
        const blogCommentData = {
            body,
            author,
            email,
            date: new Date(),
        };
        const docRef = await addDoc(collection(db, 'blogposts', id, "comments"), blogCommentData);
        console.log("Blog comment created with ID: ", docRef.id);
        return docRef;
    } catch (error) {
        console.error("Error adding document: ", error);
        throw error; // Rethrow the error to handle it in the component
    }
}

export async function CreateBlogPostReply(postId, commentId, body, author, email, originalPoster) {
    try {
        // Create replyInfo as an object, not an array
        const replyInfo = {
            body: body,
            author: author,
            email: email,
            date: new Date(),
            replyingTo: originalPoster
        };
        console.log(replyInfo);

        const commentRef = doc(db, 'blogposts', postId, "comments", commentId);

        // Check if the 'replies' field exists
        const docSnap = await getDoc(commentRef);
        if (!docSnap.exists()) {
            console.error("Comment document does not exist!");
            return;
        }

        // const currentData = docSnap.data();
        // if (!currentData.replies) {
        //     // If 'replies' field doesn't exist, initialize it as an empty array
        //     await updateDoc(commentRef, { replies: [] });
        // }

        // Now safely append the replyInfo object to the 'replies' array
        await updateDoc(commentRef, {
            replies: arrayUnion(replyInfo)  // Appending an object, not an array
        });

        console.log("Reply added successfully to comment ID: ", commentId);
    } catch (error) {
        console.error("Error adding reply: ", error.message || error);
        throw error; // Rethrow the error to handle it in the component
    }
}

